import React, { FC } from "react";
import { Cell, Pie, PieChart } from "recharts";
import styled from "../../styles/styled";

interface Props {
  answersCompiled: {
    A: number;
    B: number;
    C: number;
    D: number;
  };
}

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: {
  cx: any;
  cy: any;
  midAngle: any;
  innerRadius: any;
  outerRadius: any;
  percent: any;
  index: any;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const AnswersPie: FC<Props> = ({ answersCompiled }) => {
  console.log(answersCompiled);

  const data = Object.keys(answersCompiled).map((letter: string) => ({
    name: letter,
    // @ts-ignore
    value: answersCompiled[letter],
  }));

  console.log(data);

  return (
    <Wrapper>
      <PieChart width={400} height={400}>
        <Pie
          data={data}
          cx={200}
          cy={200}
          labelLine={false}
          // @ts-ignore
          label={renderCustomizedLabel}
          outerRadius={160}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <ul>
        <li>
          <LabelBall color={COLORS[0]}></LabelBall>A
        </li>
        <li>
          <LabelBall color={COLORS[1]}></LabelBall>B
        </li>
        <li>
          <LabelBall color={COLORS[2]}></LabelBall>C
        </li>
        <li>
          <LabelBall color={COLORS[3]}></LabelBall>D
        </li>
      </ul>
    </Wrapper>
  );
};

const LabelBall = styled.span<{ color: string }>`
  width: 1rem;
  height: 1rem;
  background: ${(p) => p.color};
  border-radius: 50%;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  ul {
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    li {
      display: flex;
      align-items: center;
      span {
        margin-right: 0.5rem;
      }
    }
  }
`;

export default AnswersPie;
