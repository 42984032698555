import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Start from "./pages/Start";
import QuestionVideo from "./pages/QuestionVideoPage";
import QuestionAnswer from "./pages/QuestionAnswerPage";
import End from "./pages/EndPage";
import DashboardQuestionsPage from "./pages/DashboardQuestionsPage";
import DashboardMessagesPage from "./pages/DashboardMessagesPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Start></Start>
          </Route>
          <Route exact path="/:questionId/video">
            <QuestionVideo></QuestionVideo>
          </Route>
          <Route exact path="/:questionId/answer">
            <QuestionAnswer></QuestionAnswer>
          </Route>
          <Route exact path="/finished">
            <End></End>
          </Route>
          <Route exact path="/dashboard/questions">
            <DashboardQuestionsPage></DashboardQuestionsPage>
          </Route>
          <Route exact path="/dashboard/messages">
            <DashboardMessagesPage></DashboardMessagesPage>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
