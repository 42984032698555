import useStorageState from "./useStorageState";
import { getLocalStorage } from "../helpers/storage";

const useCurrentQuestionStorage = (): [
  QuestionStatus | null,
  {
    markFirstPlay: () => void;
    markMovedToAnswer: () => void;
    countPlay: () => void;
    chooseOption: (option: string) => void;
    toggleShowAnswer: () => void;
  }
] => {
  const [firstPlayOn, setFirstPlayOn] = useStorageState("", "ve_first_play_on");
  const [movedToAnswerOn, setMovedToAnswerOn] = useStorageState(
    "",
    "ve_moved_answer_on"
  );
  const [timesPlayed, setTimesPlayed] = useStorageState(0, "ve_times_played");
  const [optionChosen, setOptionChosen] = useStorageState(
    "",
    "ve_option_chosen"
  );
  const [showAnswer, setShowAnswer] = useStorageState(false, "ve_show_answer");

  const markFirstPlay = () => {
    setFirstPlayOn(`${Date.now()}`);
  };

  const markMovedToAnswer = () => {
    setMovedToAnswerOn(`${Date.now()}`);
  };

  const countPlay = () => {
    setTimesPlayed(getLocalStorage("ve_times_played", true) + 1);
  };

  const toggleShowAnswer = () => {
    setShowAnswer(true);
  };

  const chooseOption = (value: string) => {
    setOptionChosen(value);
  };

  return [
    {
      firstPlayOn,
      movedToAnswerOn,
      timesPlayed,
      optionChosen,
      showAnswer,
    },
    {
      markFirstPlay,
      countPlay,
      markMovedToAnswer,
      chooseOption,
      toggleShowAnswer,
    },
  ];
};

export default useCurrentQuestionStorage;
