import React, { FC, useState, FormEvent, useEffect } from "react";
import styled from "../styles/styled";
import { Title1, Title2 } from "../styles/Titles";
import { Button } from "../styles/Buttons";
import TextInput from "../components/app/TextInput";
import { setLocalStorage } from "../helpers/storage";
import { EMAIL_LOCAL_KEY } from "../settings";
import QuestionsLayout from "../components/layout/QuestionsLayout";
import useResults from "../hooks/useResults";

const End: FC = () => {
  const [results] = useResults();

  const answeredAmount = results.length;
  const gotRightAmount = results.reduce(
    (total, result) =>
      result.chosenAnswer === result.correctAnswer ? total + 1 : total,
    0
  );
  const gotRightPercentage = answeredAmount
    ? (gotRightAmount / answeredAmount) * 100
    : 0;

  console.log(gotRightPercentage);

  return (
    <QuestionsLayout>
      <EndStyled>
        <Title1>Concluído</Title1>
        <PercentageCircle positive={gotRightPercentage >= 50}>
          {gotRightPercentage}%
        </PercentageCircle>
        <Title2>Você acertou {gotRightPercentage}%</Title2>
      </EndStyled>
    </QuestionsLayout>
  );
};

const EndStyled = styled.div`
  text-align: center;
  display: grid;
  gap: 1rem;
  justify-items: center;
`;

const PercentageCircle = styled.p<{ positive?: boolean }>`
  font-size: 2rem;
  color: ${(p) => (p.positive ? "#2ECC40" : "#FF4136")};
  height: 8rem;
  width: 8rem;
  border: 6px solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default End;
