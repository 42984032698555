import React, { FC } from "react";
import styled from "../../styles/styled";
import { Link } from "react-router-dom";

const DashboardLayout: FC = ({ children }) => {
  return (
    <Layout>
      <Nav>
        <ul>
          <li>
            <Link to="/dashboard/questions">Questões</Link>
          </li>
          <li>
            <Link to="/dashboard/messages">Mensagens</Link>
          </li>
        </ul>
      </Nav>
      {children}
    </Layout>
  );
};

const Layout = styled.div`
  padding: 1rem;
  display: grid;
  gap: 2rem;
`;

const Nav = styled.nav`
  ul {
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: start;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export default DashboardLayout;
