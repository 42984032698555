import { useHistory } from "react-router-dom";
import { removeLocalStorage, clearLocalStorage } from "../helpers/storage";
import { EMAIL_LOCAL_KEY, CURRENT_QUESTION, RESULTS_KEY } from "../settings";

const useRestart = (): ((redir?: boolean) => void) => {
  const history = useHistory();

  const onRestart = (redir = true) => {
    clearLocalStorage();
    if (redir) {
      history.push("/");
    }
  };

  return onRestart;
};

export default useRestart;
