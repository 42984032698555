import { useEffect, useState } from "react";

declare global {
  interface Window {
    YT: any;
    onYouTubeIframeAPIReady: any;
  }
}

const useYoutubeAPI = (): boolean => {
  const [ready, setReady] = useState(!!window.YT);

  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement("script");

      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      if (firstScriptTag) {
        firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
      }

      window.onYouTubeIframeAPIReady = () => setReady(true);
    }
  }, []);

  return ready;
};

export default useYoutubeAPI;
