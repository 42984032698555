import { questions } from "../mocks/questions";

// TODO pull this from the new API endpoint /api/questions
const useQuestion = (id: number | string): Question | null => {
  // not checking type in comparison since id comes as a string from router

  const question = questions.find((q) => q.id == id);

  return question || null;
};

export default useQuestion;
