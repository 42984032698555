import { useEffect } from "react";
import { CURRENT_QUESTION, RESULTS_KEY } from "../settings";
import { getLocalStorage, setLocalStorage } from "../helpers/storage";

interface Result {
  questionId: string;
  correctAnswer: string;
  chosenAnswer: string;
}

const useResults = (): [
  Result[],
  {
    addResult: (result: Result) => Result[];
  }
] => {
  const addResult = (result: Result) => {
    const previous = getLocalStorage(RESULTS_KEY, true) || [];
    const updated = [...previous, result];
    setLocalStorage(RESULTS_KEY, updated, true);
    return updated;
  };

  return [
    getLocalStorage(RESULTS_KEY, true) || [],
    {
      addResult,
    },
  ];
};

export default useResults;
