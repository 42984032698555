export const getLocalStorage = (key: string, parse = false) => {
  if (typeof window === "undefined") return null;

  const retrieved = window.localStorage.getItem(key);
  if (!retrieved) return null;

  return parse ? JSON.parse(retrieved) : retrieved;
};

export const setLocalStorage = (key: string, value: any, stringify = false) => {
  if (typeof window === "undefined") return;
  if (!value || typeof value === "undefined") return;

  window.localStorage.setItem(key, stringify ? JSON.stringify(value) : value);
};

export const removeLocalStorage = (key: string) => {
  if (typeof window === "undefined") return;
  return window.localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  if (typeof window === "undefined") return;
  return window.localStorage.clear();
};
