import React, { FC, useState } from "react";
import useRestart from "../../hooks/useRestart";
import styled from "../../styles/styled";
import Icon from "../app/Icon";
import MessageForm from "../messages/MessageForm";

const QuestionsLayout: FC = ({ children }) => {
  const restart = useRestart();
  const [showHelp, setShowHelp] = useState(false);

  return (
    <Layout>
      {children}
      {window.location.pathname !== "/" && (
        <BottomActions>
          <ActionWrapper onClick={() => restart()}>
            <span className="restart">
              recomeçar <Icon icon="spinner" size="0.8rem"></Icon>
            </span>
          </ActionWrapper>
          <ActionWrapper onClick={() => setShowHelp(true)}>
            <span className="restart">
              ajuda <Icon icon="lifebuoy" size="0.9rem"></Icon>
            </span>
          </ActionWrapper>
        </BottomActions>
      )}
      {showHelp && (
        <Help>
          <div className="box">
            <span onClick={() => setShowHelp(false)} className="close">
              x
            </span>
            <MessageForm></MessageForm>
          </div>
        </Help>
      )}
    </Layout>
  );
};

const Help = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: ${({ theme }) => theme.border.small};
    padding: 2rem 1rem;
    max-height: 80vh;
    min-width: 20rem;
    max-width: 90vw;
    .close {
      position: absolute;
      right: 1rem;
      top: 0.5rem;
      font-size: 1.1rem;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

const Layout = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-rows: 9fr 1fr;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const BottomActions = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
`;

const ActionWrapper = styled.div`
  text-align: center;
  font-size: 0.9rem;
  color: #716f6f;
  cursor: pointer;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    fill: #716f6f;
    margin-left: 0.2rem;
  }
`;

export default QuestionsLayout;
