import React, { FC, useEffect, useState } from "react";
import styled from "../styles/styled";
import { Title1, Subtitle1 } from "../styles/Titles";
import { Button } from "../styles/Buttons";
import { useHistory, useParams } from "react-router-dom";
import useQuestion from "../hooks/useQuestion";
import useYoutubeAPI from "../hooks/useYoutubeAPI";
import useCurrentQuestionStatus from "../hooks/useCurrentQuestionStatus";
import QuestionsLayout from "../components/layout/QuestionsLayout";

let player;

const QuestionVideo: FC = () => {
  const [videoPlayed, setVideoPlayed] = useState(false);
  const history = useHistory();
  const { questionId } = useParams();
  const question = useQuestion(questionId);
  const ytReady = useYoutubeAPI();
  const [
    questionStatus,
    { markFirstPlay, countPlay, markMovedToAnswer },
  ] = useCurrentQuestionStatus();

  const onPlayerReady = (event: any) => console.log(`player ready`);
  const onPlayerStateChange = (event: any) => {
    if (event.data === 1) {
      setVideoPlayed(true);
      if (!questionStatus?.firstPlayOn) {
        markFirstPlay();
      }
    }
    if (event.data === 0) {
      countPlay();
    }
  };

  useEffect(() => {
    if (ytReady) {
      // @ts-ignore
      player = new window.YT.Player("player", {
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
        //host: `${window.location.protocol}//www.youtube.com`,
      });
    }
  }, [ytReady]);

  const onAnswer = () => {
    history.push(`/${questionId}/answer`);
  };

  if (!question) return <div>Não encontrado</div>;

  return (
    <QuestionsLayout>
      <QuestionVideoStyled>
        <Title1>Pergunta {question.id}</Title1>
        <Subtitle1>
          Assista ao vídeo abaixo e depois continue para responder
        </Subtitle1>
        <IframeWrapper>
          <iframe
            src={`https://www.youtube.com/embed/${question.videoYoutubeId}?enablejsapi=1`}
            frameBorder="0"
            id="player"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="Vídeo"
          ></iframe>
          {/* <div id="player"></div> */}
        </IframeWrapper>
        <AnswerButton
          //disabled={!questionStatus?.firstPlayOn && !videoPlayed}
          onClick={onAnswer}
        >
          responder
        </AnswerButton>
      </QuestionVideoStyled>
    </QuestionsLayout>
  );
};

const QuestionVideoStyled = styled.div`
  display: grid;
  gap: 1.5rem;
  justify-items: center;
  text-align: center;
`;

const AnswerButton = styled(Button)`
  justify-self: end;
`;

const IframeWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  &::after {
    padding-top: 56.25%;
    display: block;
    content: "";
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default QuestionVideo;
