import React, { useState, FC } from "react";
import TextAreaInput from "../app/TextAreaInput";
import { Button } from "../../styles/Buttons";
import { Subtitle1 } from "../../styles/Titles";
import styled from "../../styles/styled";
import { getLocalStorage } from "../../helpers/storage";
import { EMAIL_LOCAL_KEY } from "../../settings";

const sendMessage = async (message: string) => {
  const email = getLocalStorage(EMAIL_LOCAL_KEY);
  if (!email) return;
  const res = await fetch("/api/messages", {
    method: "POST",
    body: JSON.stringify({ email, message }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const { messageId } = await res.json();
  console.log(messageId);
};

const MessageForm: FC = () => {
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const onSubmit = async (e: any) => {
    setSending(true);
    e.preventDefault();

    await sendMessage(message);

    setSending(false);
    setSent(true);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Subtitle1>{sent ? "Dúvida enviada" : "Envie sua dúvida"}</Subtitle1>
      {!sent && (
        <TextAreaInput
          name="message"
          value={message}
          onChange={(e) => setMessage(e.currentTarget.value)}
        ></TextAreaInput>
      )}
      {!sent && (
        <Button disabled={sending || sent}>
          {sending ? "enviando" : "enviar"}
        </Button>
      )}
    </Form>
  );
};

const Form = styled.form`
  display: grid;
  gap: 1rem;
  text-align: center;
  button {
    justify-self: end;
  }
`;

export default MessageForm;
