import React, { FC } from "react";
import { useParams } from "react-router-dom";
import QuestionsLayout from "../components/layout/QuestionsLayout";
import Question from "../components/questions/Question";

const QuestionAnswer: FC = () => {
  const { questionId } = useParams();

  return (
    <QuestionsLayout>
      <Question questionId={questionId}></Question>
    </QuestionsLayout>
  );
};

export default QuestionAnswer;
