import React, { FC, useState, FormEvent, useEffect } from "react";
import styled from "../styles/styled";
import { Title1 } from "../styles/Titles";
import { Button } from "../styles/Buttons";
import TextInput from "../components/app/TextInput";
import { useHistory } from "react-router-dom";
import { setLocalStorage } from "../helpers/storage";
import { EMAIL_LOCAL_KEY } from "../settings";
import QuestionsLayout from "../components/layout/QuestionsLayout";
import useRestart from "../hooks/useRestart";

const Start: FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const restart = useRestart();

  useEffect(() => {
    restart(false);
  }, []);

  const onStart = (e: FormEvent) => {
    e.preventDefault();
    if (!email) {
      alert("Preencha o e-mail!");
      return;
    }

    setLocalStorage(EMAIL_LOCAL_KEY, email);
    history.push("/1/video");
  };

  return (
    <QuestionsLayout>
      <StartStyled>
        <form onSubmit={onStart}>
          <Title1>Vamos começar!</Title1>
          <InputFull
            onChange={(e) => setEmail(e.currentTarget.value)}
            value={email}
            name="email"
            type="email"
            placeHolder="seu melhor e-mail"
            data-testid="email-input"
          ></InputFull>
          <Button>começar</Button>
        </form>
      </StartStyled>
    </QuestionsLayout>
  );
};

const StartStyled = styled.div`
  form {
    display: grid;
    gap: 1.5rem;
    justify-items: center;
  }
`;

const InputFull = styled(TextInput)`
  justify-self: stretch;
`;

export default Start;
