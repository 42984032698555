import React, { FC } from "react";
import styled from "../../styles/styled";
import { Title2, Title3 } from "../../styles/Titles";
import { differenceInSeconds } from "date-fns";
import AnswersPie from "./AnswersPie";

interface Props {
  question: Question;
  answers: Answer[];
}

const Answer: FC<{ question: Question; answer: Answer }> = ({
  question,
  answer,
}) => {
  const timeWatching = differenceInSeconds(
    new Date(parseInt(answer.questionStatus.movedToAnswerOn, 10)),
    new Date(parseInt(answer.questionStatus.firstPlayOn, 10))
  );

  return (
    <tr>
      <TableCell>{answer.email}</TableCell>
      <TableCell>
        {answer.questionStatus.optionChosen === question.correctOptionId
          ? "sim"
          : "não"}
      </TableCell>
      <TableCell>{answer.questionStatus.optionChosen}</TableCell>
      <TableCell>
        {timeWatching <= 60
          ? `${timeWatching}s`
          : `${Math.floor(timeWatching / 60)}m`}
      </TableCell>
      <TableCell>{answer.questionStatus.timesPlayed + 1}</TableCell>
    </tr>
  );
};

const QuestionStats: FC<Props> = ({ question, answers }) => {
  const answersCompiled = answers.reduce(
    (acum, answer) => {
      // @ts-ignore
      acum[answer.questionStatus.optionChosen] += 1;
      return acum;
    },
    {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    }
  );

  return (
    <QuestionStatsStyled>
      <Title2>Pergunta {question.id}</Title2>
      <ul>
        <li>
          <strong>Título:</strong> "{question.title}"
        </li>
        <li>
          <strong>Resposta correta: </strong>
          {question.correctOptionId} -{" "}
          {
            question.options.find((op) => op.id === question.correctOptionId)
              ?.text
          }
        </li>
      </ul>
      <Title3>Resumo</Title3>
      <AnswersPie answersCompiled={answersCompiled}></AnswersPie>
      <Title3>Respostas</Title3>
      <Table cellSpacing="0">
        <TableHeader>
          <tr>
            <TableHeaderTitle>email</TableHeaderTitle>
            <TableHeaderTitle>acertou</TableHeaderTitle>
            <TableHeaderTitle>resposta</TableHeaderTitle>
            <TableHeaderTitle>tempo assistido</TableHeaderTitle>
            <TableHeaderTitle>views</TableHeaderTitle>
          </tr>
        </TableHeader>
        <TableBody>
          {answers.map((answer) => (
            <Answer
              key={answer.id}
              question={question}
              answer={answer}
            ></Answer>
          ))}
        </TableBody>
      </Table>
    </QuestionStatsStyled>
  );
};

const Table = styled.table`
  width: 100%;
  min-width: 50rem;
`;

const TableHeader = styled.thead`
  text-align: center;
`;

const TableHeaderTitle = styled.th`
  cursor: pointer;
  padding: 0 0 1rem;
`;

const TableBody = styled.tbody`
  tr {
    &:first-of-type td {
      margin-top: 1rem;
    }
    &:not(:last-of-type) td {
      border-bottom: 1px solid ${({ theme }) => theme.colors.greyDarker};
    }
    &:nth-child(odd) {
      background: ${({ theme }) => theme.colors.grey};
    }
    &:hover {
      cursor: pointer;
      td {
        background: ${({ theme }) => theme.colors.blue};
        color: white;
      }
    }
  }
`;

const TableCell = styled.td`
  transition: all 0.2s;
  padding: 1rem;
  text-align: center;
`;

const QuestionStatsStyled = styled.div`
  display: grid;
  gap: 1rem;
  text-align: left;
`;

export default QuestionStats;
