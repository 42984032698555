import React, { FC, useEffect } from "react";
import useQuestion from "../../hooks/useQuestion";
import useResults from "../../hooks/useResults";
import useCurrentQuestionStatus from "../../hooks/useCurrentQuestionStatus";
import { Title1, Subtitle1, Title2 } from "../../styles/Titles";
import { Button } from "../../styles/Buttons";
import styled from "../../styles/styled";
import { css } from "@emotion/core";
import { useHistory } from "react-router-dom";
import { getLocalStorage } from "../../helpers/storage";
import { EMAIL_LOCAL_KEY } from "../../settings";

interface Props {
  questionId: string;
}

const sendAnswer = async (answer: any) => {
  const res = await fetch("/api/answers", {
    method: "POST",
    body: JSON.stringify({ ...answer }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const { answerId } = await res.json();
  console.log(answerId);
};

const Question: FC<Props> = ({ questionId }) => {
  const question = useQuestion(questionId);
  const [
    questionStatus,
    { chooseOption, toggleShowAnswer, markMovedToAnswer },
  ] = useCurrentQuestionStatus();
  const [_, { addResult }] = useResults();
  const history = useHistory();

  useEffect(() => {
    markMovedToAnswer();
  }, []);

  const onAnswerSubmit = async () => {
    if (
      !questionStatus?.showAnswer &&
      questionStatus?.optionChosen &&
      question?.correctOptionId
    ) {
      toggleShowAnswer();
      addResult({
        questionId,
        chosenAnswer: questionStatus.optionChosen,
        correctAnswer: question.correctOptionId,
      });
      await sendAnswer({
        question,
        questionStatus,
        email: getLocalStorage(EMAIL_LOCAL_KEY),
      });
      return;
    }
    // navigate to the next one
    history.push("/finished");
  };

  if (!question) return <div>Não encontrado</div>;
  if (!questionStatus) return null;

  const { showAnswer, optionChosen } = questionStatus;

  return (
    <Wrapper>
      <Title1>Pergunta {question.id}</Title1>
      <Subtitle1>Com base no vídeo assistido, responda:</Subtitle1>
      <Title2 data-testid="question-title">{question.title}</Title2>
      <OptionsList>
        {question.options.map((option) => (
          <Option
            onClick={() => !showAnswer && chooseOption(option.id)}
            selected={optionChosen === option.id}
            key={option.id}
            data-testid="option"
            wrong={
              showAnswer &&
              optionChosen === option.id &&
              question.correctOptionId !== option.id
            }
            correct={showAnswer && question.correctOptionId === option.id}
          >
            <span>{option.id}</span> <p>{option.text}</p>
          </Option>
        ))}
      </OptionsList>
      <AnswerButton disabled={!optionChosen} onClick={onAnswerSubmit}>
        {showAnswer ? "Continuar" : "Enviar"}
      </AnswerButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  justify-items: center;
  text-align: center;
`;

const AnswerButton = styled(Button)`
  justify-self: end;
`;

const OptionsList = styled.ul`
  display: grid;
  gap: 0.5rem;
`;

interface OptionProps {
  selected?: boolean;
  correct?: boolean;
  wrong?: boolean;
}
const Option = styled.li<OptionProps>`
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1rem;
  align-items: center;
  background: #f1f1f1;
  border: 1px dashed #979797;
  border-radius: ${({ theme }) => theme.border.small};
  transition: all .2s;
  cursor: pointer;
  span {
    font-size: 1.5rem;
    font-weight: bold;
  }
  p {
    text-align: left;
  }

  ${(p) =>
    p.selected &&
    css`
      background: rgba(0, 116, 217, 0.1);
      color: #0074d9;
      border-color: #0074d9;
    `}
  
  ${(p) =>
    p.correct &&
    css`
      background: rgba(46, 204, 64, 0.09);
      color: #2ecc40;
      border-color: #2ecc40;
    `}

  ${(p) =>
    p.wrong &&
    css`
      background: rgba(255, 65, 54, 0.08);
      color: #ff4136;
      border-color: #ff4136;
    `}
`;

export default Question;
