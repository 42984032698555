import React, { FC, useState, useEffect } from "react";
import DashboardLayout from "../components/layout/DashboardLayout";
import styled from "../styles/styled";
import { Title1 } from "../styles/Titles";
import QuestionStats from "../components/questions/QuestionStats";

const DashboardQuestionsPage: FC = () => {
  const [questions, setQuestions] = useState<Answer[]>([]);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchAnswers = async () => {
    const [questionsData, answersData] = await Promise.all([
      await (await fetch("/api/questions")).json(),
      await (await fetch("/api/answers")).json(),
    ]);
    setQuestions(questionsData.questions);
    setAnswers(answersData.answers);
    setLoading(false);
  };

  useEffect(() => {
    fetchAnswers();
  }, []);

  return (
    <DashboardLayout>
      <QuestionsStyled>
        <Title1>Questões</Title1>
        {loading && <span>carregando...</span>}
        {!loading &&
          questions.map((question) => (
            <QuestionStats
              key={question.id}
              // @ts-ignore
              question={question}
              answers={answers.filter((a) => a.question.id == question.id)}
            ></QuestionStats>
          ))}
      </QuestionsStyled>
    </DashboardLayout>
  );
};

const QuestionsStyled = styled.div`
  text-align: center;
  display: grid;
  gap: 1rem;
  justify-items: center;
`;

export default DashboardQuestionsPage;
