export const questions: Question[] = [
  {
    id: "1",
    title: "É correto afirmar que:",
    correctOptionId: "D",
    videoYoutubeId: "VJF9GPtpiGA",
    options: [
      {
        id: "A",
        text: "O personagem principal tem duas marretas na mão.",
      },
      {
        id: "B",
        text: "O personagem principal tem uma metralhadora na mão.",
      },
      {
        id: "C",
        text: "O filme é colorido.",
      },
      {
        id: "D",
        text: "O personagem principal utiliza duas chaves de boca.",
      },
    ],
  },
];
