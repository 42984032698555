import React, { FunctionComponent } from "react";
import styled from "../../styles/styled";

const IconSVG = styled.svg<{
  size: string;
  color: string;
  alt: string;
}>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  fill: ${(props) => props.color};
`;

interface Props {
  size?: string;
  color?: string;
  className?: string;
  icon: string;
  alt?: string;
}

const Icon: FunctionComponent<Props> = ({
  size = "1.5rem",
  color = "#000",
  icon,
  className = "",
  alt = "",
}) => (
  <IconSVG size={size} color={color} className={className} alt={alt}>
    <use xlinkHref={`/icons/symbol-defs.svg#icon-${icon}`} />
  </IconSVG>
);

export default Icon;
