import React, { FC, useState, FormEvent, useEffect } from "react";
import styled from "../styles/styled";
import { Title1, Title2 } from "../styles/Titles";
import { Button } from "../styles/Buttons";
import TextInput from "../components/app/TextInput";
import DashboardLayout from "../components/layout/DashboardLayout";
import useResults from "../hooks/useResults";

const DashboardMessagesPage: FC = () => {
  const [loading, setLoading] = useState(true);

  const [helpMessages, setMessages] = useState<
    { id: string; email: string; message: string }[]
  >([]);

  const fetchMessages = async () => {
    const data = await (await fetch("/api/messages")).json();
    setMessages(data.messages);
    setLoading(false);
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  console.log(helpMessages);

  return (
    <DashboardLayout>
      <QuestionsStyled>
        <Title1>Mensagens</Title1>
        {loading && <span>carregando...</span>}
        {!loading && (
          <MessageList>
            {helpMessages.map((helpMessage) => (
              <li key={helpMessage.id}>
                <p>
                  <strong>De:</strong> {helpMessage.email}
                </p>
                <p>{helpMessage.message}</p>
              </li>
            ))}
          </MessageList>
        )}
      </QuestionsStyled>
    </DashboardLayout>
  );
};

const MessageList = styled.ul`
  display: grid;
  gap: 0.5rem;
  min-width: 40rem;
  text-align: left;
  li {
    &:not(:first-of-type) {
      padding-top: 0.5rem;
      border-top: 1px solid ${({ theme }) => theme.colors.grey};
    }
  }
`;

const QuestionsStyled = styled.div`
  text-align: center;
  display: grid;
  gap: 1rem;
  justify-items: center;
`;

export default DashboardMessagesPage;
